import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

// material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { GoogleMapsModule } from '@angular/google-maps';

// table
import { CdkTableModule } from '@angular/cdk/table';

// sharing & brand icons
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// auth is now primarily handled with firebase
import { environment } from '../environments/environment';
import { MarketAuth } from './marketauth.service';

// cookies
import { CookieModule } from 'ngx-cookie';

// lazy-loading of images in tiles view
import { LazyLoadImageModule } from 'ng-lazyload-image';

// firebase (for auth and messenging)
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
//import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

// local components
import { AppComponent }           from './app.component';
import { TilesComponent }         from './tiles.component';
import { HomeComponent }          from './home.component';
import { TagComponent }           from './tag.component';
import { MapComponent }           from './map/map.component';
import { SoundDetailComponent }   from './sound-detail.component';
import { TagsComponent }          from './tags.component';
import { CommentsComponent }      from './comments.component';
import { SearchComponent }        from './search.component';
import { UserComponent }          from './user.component';
import { TokenComponent }         from './token.component';
import { ChatterComponent }       from './chatter.component';
import { UsersComponent }         from './users.component';
import { NotificationsComponent } from './notifications.component';
import { BadgesComponent }        from './badges.component';
import { AvatarsComponent }       from './avatars.component';
import { DeleteComponent }        from './account/delete.component';

// services
import { MetaService }          from './meta.service';
import { MarketService }        from './market.service';
import { TMApiService }         from './store/tmapi.service';
import { MessagingService }     from './messaging.service';

// pipes
import { LinkifyPipe, LinkifyNonePipe, TrimPipe } from './linkify.pipe';
import { HighlightPipe }                          from './highlight.pipe';

// dialogs
import { LoginDialog }          from './login.dialog';
import { SoundDownloadDialog}   from './sound-download.dialog';
import { ConfigDialog }         from './config.dialog';
import { ConfirmDialog }        from './confirm.dialog';

// static content
import { HelpComponent }        from './help.component';
import { DownloadComponent }    from './download.component';
import { TermsComponent }       from './terms.component';
import { PrivacyComponent }     from './privacy.component';

// routes
import { AppRoutingModule }     from './app-routing.module';

// ads
import { AdTmsoftComponent }    from './ad-tmsoft.component';
import { AdHeaderComponent }    from './ad-header.component';
import { AdTileComponent }      from './ad-tile.component';
import { AdDownloadComponent }  from './ad-download.component';
import { AvatarComponent }      from './avatar/avatar.component';

// mp3 shopping cart
import { CatalogComponent }     from './store/catalog/catalog.component';
import { CheckoutComponent }    from './store/cart/checkout.component';
import { OrdersComponent }      from './store/orders/orders.component';
import { CartAddDialog }        from './store/cart/cart-add.dialog';
import { TermsDialog }          from './store/terms-dialog/terms-dialog';
import { NgxPayPalModule }      from 'ngx-paypal';

@NgModule({
    imports: [BrowserModule, FormsModule, AppRoutingModule, HttpClientJsonpModule, HttpClientModule,
        BrowserAnimationsModule, GoogleMapsModule, ShareButtonsModule, ShareIconsModule, FontAwesomeModule,
        CookieModule.forRoot(), LazyLoadImageModule,
        MatListModule, MatSidenavModule, MatCardModule, MatMenuModule, MatDialogModule, MatTabsModule, MatToolbarModule,
        MatTableModule, MatButtonModule, MatInputModule, MatFormFieldModule, MatProgressBarModule, MatProgressSpinnerModule, MatSnackBarModule,
        MatSelectModule, MatChipsModule, MatIconModule, MatExpansionModule, CdkTableModule, NgxPayPalModule,
        AngularFireAuthModule, AngularFireMessagingModule, AngularFireModule.initializeApp(environment.firebase),],
    declarations: [AppComponent, TilesComponent, HomeComponent, TagComponent, SoundDetailComponent, TagsComponent, CommentsComponent,
        MapComponent, UsersComponent, SearchComponent, UserComponent, NotificationsComponent, BadgesComponent, AvatarsComponent,
        HelpComponent, DownloadComponent, TermsComponent, PrivacyComponent, TokenComponent, ChatterComponent,
        LinkifyPipe, LinkifyNonePipe, TrimPipe, HighlightPipe,
        LoginDialog, SoundDownloadDialog, ConfigDialog, ConfirmDialog, CartAddDialog, CatalogComponent,
        AdTmsoftComponent, AdTileComponent, AdDownloadComponent, AdHeaderComponent, AvatarComponent, CheckoutComponent, OrdersComponent,
        TermsDialog, DeleteComponent],
    providers: [MarketService, MarketAuth, TMApiService, MetaService, MessagingService],
    bootstrap: [AppComponent]
})

export class AppModule { }
