import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { MarketService } from '../market.service';
import { MetaService } from '../meta.service';
import { MarketAuth } from '../marketauth.service';
import { Sound } from '../sound';
import { Tag } from '../tag';
import { User } from '../user';
import { Setting } from '../account/setting';

//import { MessagingService } from '../messaging.service';
//import { CookieService } from 'ngx-cookie';

import { Router, ActivatedRoute, Params }   from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.css']
})

export class MeComponent {

  user: null | any = null;
  working: boolean = false;
  authorized=false;  
  webLink: string | null = null;
  displayLink: string | null = null;
  badges: any[] = [];

  // other profile data
  favSounds: Sound[] = [];
  favTags: Tag[] = [];
  favUsers: User[] = [];
  settings: Setting[] = [];
  
  private userSubscription: any; // keep track of observable so it can be removed later
  
  constructor(protected marketService: MarketService,
            protected marketAuth: MarketAuth,
            private metaService: MetaService,
            private router: Router,
            private route: ActivatedRoute,
            private sanitizer: DomSanitizer,            
            private cdRef: ChangeDetectorRef,
            private snackBar: MatSnackBar)
  {
    const title = "My White Noise - White Noise Market";   
    const description = "My White Noise feed, uploaded sounds, and settings.";
    const image = "/assets/images/white-noise-market.png";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }

  // called after ngInit
  public ngOnInit()
  {
    // subscribe for auth updates
    this.userSubscription = this.marketAuth.user.subscribe(user => this.setUser(user));
  }

  ngOnDestroy()
  {
    // remove observable
    if (this.userSubscription) 
    {
      this.userSubscription.unsubscribe();
    }
  }

  private setUser(user : any)
  {
      this.user = user;
      this.authorized = (user != null);

      // pull profile, favorites, and settings
      if (this.authorized)
      {
        this.marketService.getMeProfile()
          .then(data => {
            console.log("Received me data: ", data);
            this.setProfile(data.Profile);
            this.settings = data.Settings as Setting[];
            this.favTags = data.Favorites.Tags as Tag[];
            this.favSounds = data.Favorites.Sounds as Sound[];
            this.favUsers = data.Favorites.Users as User[];
          })
          .catch(err => {
            this.logError(err)
            this.setProfile(null);
          });
      }
  }

  private setProfile(user: any): void
  {
    console.log("User profile:", user);
    this.webLink = null;
    this.displayLink = null;
  
    // parse user website
    if (user?.userWebsite)
    {
        this.webLink = user.userWebsite;

        // web link is what users click and display link is what is displayed          
        this.displayLink = user.userWebsite.replace(/^\/\/|^.*?:(\/\/)?/, ''); // remove protocol information for displaying

        // check if there was no protocol information and add it to web link
        if (this.webLink === this.displayLink)
        {
            this.webLink = "http://" + this.webLink;
        }

        // pretty up display link some more (after the above protocol check)
        this.displayLink = this.displayLink.replace(/\/$/, ""); // remove slash from end
        this.displayLink = this.displayLink.replace(/^(www\.)/,""); // remove www. from beginning

        console.log("User website:", this.webLink, this.displayLink);
    }
    // parse user badges
    if (user?.badges)
    {
        // {"Id":"web","Label":"Web World","ImageUrl":"https://img.tmsoft.com/badges/web.png","Description":"Login using the White Noise Market website"}
        this.marketService.getBadges()
          .then(data => this.parseBadges(data, user.badges))
          .catch(err => this.handleBadgeError(err));
    }
    /*
    if (profile.stats != null)
    {
        this.description = profile.displayName + "'s Zen Rating: " + profile.stats.zen;
        this.description += " - Incoming Hearts: " + profile.stats.hearts + " Comments: " + profile.stats.comments + " Downloads: " + profile.stats.downloads + " Minutes: " + profile.stats.minutes;
        this.description += " - Outgoing Hearts: " + profile.stats.myHearts + " Comments: " + profile.stats.myComments + " Downloads: " + profile.stats.myDownloads + " Minutes: " + profile.stats.myMinutes;
    }
    */
  }

  private parseBadges(all : any[], badges : string) : void
  {
      if (all == null || all.length == 0 || badges == null || badges.length == 0) return;

      let result = [];
      
      for (var i = 0; i < all.length; ++i) 
      {
          let a : any = all[i];
          // {"Id":"web","Label":"Web World","ImageUrl":"https://img.tmsoft.com/badges/web.png","Description":"Login using the White Noise Market website"}
          if (badges.includes(a.Id))
          {
              // add user badge to front of array as the items at the end are more important badges (tougher to get)
              result.unshift(a);
          }
      }
      
      this.badges = result;
      //console.info("Returning total user badges: " + this.badges.length);
  }

  private handleBadgeError(error : any) : void
  {
    console.info("Server had error requesting badges: " + JSON.stringify(error));
  }

  onTabChange($event: any) : void
  {
    let clickedIndex = $event.index;
    console.log("Tab Change: ", clickedIndex);
  }

  showMessage(msg : string): void 
  {
    this.snackBar.open(msg, null, { duration: 3000, verticalPosition: "bottom" });
  }

  getSoundLink(sound: Sound) : string
  {
    let slug = this.marketService.webify(sound.Label);
    return "/sound/" + slug + "?id=" + sound.Uid;
  }

  logError(err: any)
  {
    console.error("Error: ", err);
  }

}