import { Component } from '@angular/core';

/*
    TMSOFT PROMO AD CODE WHEN USING AD SERVER:
    <p style="text-align: center; overflow: hidden; width: 94%; margin: auto;">  
        <iframe id='acf6392c' name='acf6392c' src='//p.tmsoft.com/a/www/d/zafr.php?zoneid=1&amp;target=_blank' frameborder='0' scrolling='no' width='728' height='90' style='border: none;  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);'>
        <a href='//p.tmsoft.com/a/www/d/ck.php?n=a8b8a9fb' target='_blank'><img src='//p.tmsoft.com/a/www/d/avw.php?zoneid=1&amp;n=a8b8a9fb' border='0' alt='' /></a>
        </iframe>
    </p>   
*/

@Component({
    selector : 'ad-tmsoft',
    styleUrls: [ './app.component.css' ],
    template : `
    <br>
    <p class="hide-lt-700" style="text-align: center; overflow: hidden; width: 94%; margin: auto;">  
        <iframe src='//p.tmsoft.com' frameborder='0' scrolling='no' width='728' height='90' style='border: none;  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);'>
        </iframe>
    </p>
    <p class="show-lt-700" style="text-align: center; overflow: hidden; width: 94%; margin: auto;">  
        <iframe src='//p.tmsoft.com/?w=332' frameborder='0' scrolling='no' width='332' height='45' style='border: none;  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);'>
    </iframe>
</p>   
    `
})
export class AdTmsoftComponent
{
}


