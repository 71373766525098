// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Database and tables */
.tables-container 
{
    display: flex;
    flex-direction: column;
 }

 .mat-column-Note
 {
   padding: 10px;
   width: 100%;
 }

 .mat-table 
{
    overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/tables.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;;IAEI,aAAa;IACb,sBAAsB;CACzB;;CAEA;;GAEE,aAAa;GACb,WAAW;CACb;;CAEA;;IAEG,cAAc;AAClB","sourcesContent":["/* Database and tables */\r\n.tables-container \r\n{\r\n    display: flex;\r\n    flex-direction: column;\r\n }\r\n\r\n .mat-column-Note\r\n {\r\n   padding: 10px;\r\n   width: 100%;\r\n }\r\n\r\n .mat-table \r\n{\r\n    overflow: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
