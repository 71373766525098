import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable()
export class MetaService
{
    constructor(private titleService: Title,
                private metaService: Meta) 
    {}

    // sets the title and other metadata using the strings provided
    updateMetadata(title: string, description: string, image: string)
    {
        this.updateTitle(title);
        this.updateDescription(description);
        this.updateImage(image);
        
        // // update title and meta data
        // this.titleService.setTitle(title);
        // this.metaService.updateTag({ name: 'description', content: description });
        // this.metaService.updateTag({ property: 'og:title', content: title });
        // this.metaService.updateTag({ property: 'og:description', content: description });
        // this.metaService.updateTag({ property: 'og:image', content: image });
        // this.metaService.updateTag({ name: 'twitter:title', content: title });
        // this.metaService.updateTag({ name: 'twitter:description', content: description });
        // this.metaService.updateTag({ name: 'twitter:image', content: image });
        
        // todo: add og:url and twitter:url?
    }

    updateTitle(title: string)
    {
        this.titleService.setTitle(title);
        this.metaService.updateTag({ property: 'og:title', content: title });
        this.metaService.updateTag({ name: 'twitter:title', content: title });
    }

    updateDescription(description: string)
    {
        this.metaService.updateTag({ name: 'description', content: description });
        this.metaService.updateTag({ property: 'og:description', content: description });
        this.metaService.updateTag({ name: 'twitter:description', content: description });        
    }

    updateImage(image: string)
    {
        this.metaService.updateTag({ property: 'og:image', content: image });        
        this.metaService.updateTag({ name: 'twitter:image', content: image });
    }
}
