import { Component, OnInit } from '@angular/core';
import { MarketService } from './market.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialog } from './confirm.dialog';
import { MarketAuth } from './marketauth.service';

@Component({
  selector: 'market-avatars',
  templateUrl: './avatars.component.html',
  styleUrls: [ './avatars.component.css' ],
})

export class AvatarsComponent implements OnInit
{
    title = "Avatars";
    subtitle = "White Noise Market User Avatars"
    data : any[];
    avatar = "/assets/images/default_user.jpg";
    working = false;

    constructor(private marketService: MarketService,
                private marketAuth: MarketAuth,
                private dialog: MatDialog)
    {}

    ngOnInit(): void
    {
      this.working = true;
      this.marketService.getAvatars().then(data => this.setData(data)).catch(err => this.setError(err));
    }

    setData(data: any[])
    {
      this.working = false;
      this.data = data;
    }

    setAvatar(url : string)
    {

      let options = {
        data: {
          title: "Change Avatar",
          message: "Do you want this image to be your avatar?",
        }
     };

    this.dialog.open(ConfirmDialog, options)
               .afterClosed()
               .subscribe(result => {
                    if (!result) return;

                    // todo: set avatar
                    console.info("Set Avatar to URL: " + url)
                    var config = {};
                    config["userAvatarUrl"] = url;
                    console.log("Set user configuration:", config);

                    this.marketService.setUserConfig( config )
                               .then(result => {
                                   console.log("Set config result: " , result);

                                   // refresh user profile
                                   this.marketAuth.updateLoginState();
                               })
                               .catch(err => this.setError(err));
                });

    }

    avatarError(image : any): void
    {
      image.onerror = null;
      image.src = "/assets/images/default_user.jpg";
    }

    setError(err: any)
    {
      console.info("Error during query!");
      this.working = false;
    }

}

