import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MarketService } from './market.service';
import { MessagingService } from "./messaging.service";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

import { TMApiService } from './store/tmapi.service';

// user configuration
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ConfigDialog } from './config.dialog';
import { MarketAuth } from './marketauth.service';

@Component({
  selector: 'market-app',
   templateUrl: './app.component.html',
   styleUrls: [ './app.component.css' ],
})

export class AppComponent implements OnInit, OnDestroy
{
  title = environment.title;
  label = environment.label;
  search = '';
  lastSize = ''; // last media size

  // helps us respond to screen size changes and change the sidenav on small vs large screens
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  loadedNav = false;

  constructor(public marketService: MarketService,
              public marketAuth: MarketAuth,
              public tmapiService: TMApiService,
              private messagingService: MessagingService,
              public snackBar: MatSnackBar,
              private dialog: MatDialog,
              private router: Router)
  {
  }

  // called after market-tags in the sidebar are loaded
  initSidenav(): void
  {
    this.loadedNav = true;
    let width = window.innerWidth;
    console.log("Initial screen width: " + width);
    this.setSidenav(width < 960);
  }

  setSidenav(isSmallScreen:Boolean): void
  {
    if (isSmallScreen)
    {
      this.sidenav.mode = 'over';
      this.sidenav.opened = false;
      this.sidenavOpenClose(false);
      console.log("Setting sidenav type 'over', closing");
    }
    else
    {
      this.sidenav.mode = 'side';
      this.sidenav.opened = true;
      this.sidenavOpenClose(true);
      console.log("Setting sidenav type 'side', opening");
    }
  }

  sidenavToggle()
  {
    if (!this.loadedNav) return;
    // make visible so we can see opening animation
    if (!this.sidenav.opened) this.sidenavOpenClose(true);
    // open or close the sidenav
    this.sidenav.toggle();
  }

  // add/remove the display-none class to fix scrolling bug on ios
  sidenavOpenClose(isOpen: boolean)
  {
    // hacky garbage, but couldn't figure out any other way to get the sidenav's html element
    var html = (this.sidenav as any)._elementRef.nativeElement as HTMLElement;
    if (isOpen)
    {
      html.classList.remove("display-none");
    }
    else
    {
      html.classList.add("display-none");
    }
  }

  ngOnInit(): void
  {
    // if browser is going directly to search location then set the search bar text to value
    if (location.pathname.startsWith("/search/"))
    {
      this.search = location.pathname.substring(8);
    }

    // TODO: This was part of flex-layout, but I'm not sure why exactly.
    /*
    this.mediaObserver.asObservable().subscribe( (changes: MediaChange[]) => {

      if (!this.loadedNav) return;
      if (changes.length == 0) return;

      var size = changes[0].mqAlias; // first element is highest priority
      if (size != this.lastSize)
      {
        this.setSidenav(size == "xs" || size == "sm");
        this.lastSize = size;
        //console.log("Media changed: " + size);
      }
    });
    */

    // auth updates
    this.marketAuth.user.subscribe(user => this.userUpdated(user));

    // push notification messages
    this.messagingService.currentMessage.subscribe(msg => this.showNotification(msg) );
    this.messagingService.receiveMessage();

    // push notification receiving token
    this.messagingService.currentToken.subscribe(token => this.receivedToken(token) );
    this.messagingService.refreshToken();

    // test notification - might want to call using setTimeout(() => { }
    //var msg = { "data": { "imageUrl": "https://lh3.googleusercontent.com/mmKu3LemlPjPBWSFRoeM-7E4VKZ8U3FPdmcav4vMCAHNSaf3gMyWNH_BwK_b08vshbTd2fKxmH8Qm-Z8GCrjCTuOwXK3ZQ", "url": "whitenoisemarket:///sound/heavy-rain-on-light-canvas-tent?id=a4aa8586cb8fa72ed2247b1f47509ac1" }, "from": "982734939705", "priority": "normal", "notification": { "body": "Heavy Rain on Light Canvas Tent Admin Test! Sent to Admin Users from App Engine Firebase API." } };
    //var msg = { "from": "982734939705", "priority": "normal", "notification": { "body": "Heavy Rain on Light Canvas Tent Admin Test! Sent to Admin Users from App Engine Firebase API." } };
    //this.showNotification(msg);
  }

  // received token will always call us back from the initial refresh (may be null)
  receivedToken(token: string)
  {
    if (token != null)
    {
      console.log("Received messaging token:", token)
      this.checkTokenAndUser();
    }
  }

  userUpdated(user: any)
  {
    if (user != null)
    {
      console.log("New user updated:", user)
      this.checkTokenAndUser();
    }
  }

  checkTokenAndUser()
  {
    // firebase messaging can be unsupported (by default it will be blocked)
    if (this.messagingService.unsupported)
    {
      return;
    }

    // need to wait until the token refresh call returns
    if (!this.messagingService.refreshed)
    {
      return;
    }

    // get active user
    var user = this.marketAuth.getUser();
    if (user == null)
    {
      return;
    }

    // check for valid token
    var token = this.messagingService.currentToken.getValue();
    if (token != null)
    {
      // check if token has changed
      if (user.pushWeb != token)
      {
        // upload the token
        console.log("Uploading new user push token")
        this.marketService.setUserConfig({"pushWeb" : token})
                          .then( result => { console.log('set user push token result:', result); } )
                          .catch( err => { console.error('error setting user push token:', err); });
      }
      else
      {
        console.log("Push token for user has not changed.");
      }
    }
    else
    {
      // check if push notifications are blocked
      if (this.messagingService.blocked)
      {
        return;
      }

      // need permission but lets prompt in a nice way with snackbar
      console.log("Showing snackbar to enable notifications")
      this.snackBar.open("Enable notifications for weekly featured sounds?", "ENABLE", { duration: 30000, verticalPosition: "bottom" }).onAction().subscribe(action => {

        // push notifications
        console.log("Requesting permission");
        this.messagingService.requestPermission();
      });
    }
  }

  showNotification(msg: any)
  {
    /* example notification
    { "data": { "imageUrl": "https://lh3.googleusercontent.com/mmKu3LemlPjPBWSFRoeM-7E4VKZ8U3FPdmcav4vMCAHNSaf3gMyWNH_BwK_b08vshbTd2fKxmH8Qm-Z8GCrjCTuOwXK3ZQ", "url": "whitenoisemarket:///sound/heavy-rain-on-light-canvas-tent?id=a4aa8586cb8fa72ed2247b1f47509ac1" }, "from": "982734939705", "priority": "normal", "notification": { "body": "Heavy Rain on Light Canvas Tent Admin Test! Sent to Admin Users from App Engine Firebase API." } }
    */
    if (msg && msg.notification && msg.notification.body)
    {
      console.log("Notification received:", msg.notification.body)
      // parse out url
      var url: string
      var action = "DISMISS"
      if (msg.data && msg.data.url && msg.data.url.length > 20)
      {
        url = msg.data.url.replace("whitenoisemarket:///", "https://whitenoisemarket.com/");
        action = "VIEW";
      }

      this.snackBar.open(msg.notification.body, action, { duration: 0, verticalPosition: "bottom" }).onAction().subscribe(action => {
        if (msg.data && msg.data.url)
        {
          // remap url from app url link to website link
          // url example: whitenoisemarket:///sound/heavy-rain-on-light-canvas-tent?id=a4aa8586cb8fa72ed2247b1f47509ac1
          if (url)
          {
            console.log("Perform action: " + url);
            window.location.href = url;
          }
        }
      });
    }
  }

  /*
  showEditDialog(data : any): void
  {
    // setup initial configuration
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    this.dialog.open(ConfigDialog, dialogConfig).afterClosed().subscribe(result =>
      {
        // firebase email logins return our market token
        console.log('Config Dialog Returned:', result);

        if (result == true)
        {
          // navigate to the user's page
          var userPage = "/user/" + this.marketAuth.getUser().userId;
          this.router.navigateByUrl(userPage);
          this.marketAuth.updateLoginState();
        }
      });
  }

  // change user name
  changeName(): void
  {
    this.showEditDialog({ name : "userName",
                          value : this.marketAuth.getUser().displayName,
                          title : "Change Name",
                          description : "Edit your name that is displayed to others.",
                          placeholder : "Name",
                          maxlength : 25 });
  }

  // change user description
  changeDescription(): void
  {
    this.showEditDialog({ name : "userDescription",
                          value : this.marketAuth.getUser().userDescription,
                          title : "Change Description",
                          description : "Edit your personal description that appears to others on your user profile.",
                          placeholder : "Description",
                          maxlength : 500  });
  }

  // change user description
  changeWebsite(): void
  {
    this.showEditDialog({ name : "userWebsite",
                          value : this.marketAuth.getUser().userWebsite,
                          title : "Change Website",
                          description : "Edit your website URL that appears to others on your user profile page.",
                          placeholder : "Website",
                          maxlength : 100 });
  }
*/

  ngAfterViewInit(): void
  {

  }

  ngOnDestroy()
  {
  }

  avatarError(image : any): void
  {
    console.warn("Failed to load avatar for user");
    image.onerror = null;
    image.src = "/assets/images/default_user.jpg";
  }
}

