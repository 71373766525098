import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Sound } from './sound';
import { MarketService } from './market.service';
import { MetaService } from './meta.service';
import { TilesComponent } from './tiles.component';

@Component({
  selector: 'market-home',
  templateUrl: './tiles.component.html',
  styleUrls: [ './tiles.component.css' ],
})

export class HomeComponent extends TilesComponent
{
    // inherited class use this after constructor
    public construct(): void // called during constuction
    {
      // meta data
      const title = "White Noise Sounds by TMSOFT - White Noise Market";
      const description = "Download free sounds to White Noise for iOS, Android, Mac, and Windows. Purchase and stream sounds at Spotify, Apple Music, Google Play Music, Amazon, and more.  Official Market for White Noise Sounds by TMSOFT.";
      const image = "/assets/images/white-noise-market.png";

      // update title and meta data
      this.metaService.updateMetadata(title, description, image);
      console.log("Home updated meta data");
    }
}
