import { Pipe, PipeTransform } from '@angular/core';

/*
import linkifyStr from 'linkifyjs/string';

@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform 
{
  transform(str: string): string 
  {
    return str ? linkifyStr(str, {target: '_blank'}) : str;
  }
}

@Pipe({name: 'linkifyshort'})
export class LinkifyShortPipe implements PipeTransform 
{
  transform(str: string): string 
  {
    return str ? linkifyStr(str, {
      target: '_blank',
      format: {
        url: function (value) {
          return "[Link]";
          //return value.length > 40 ? value.slice(0, 40) + '…' : value
        }
      }
    }) : str;
  }
}
*/

@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform 
{
  // regex for http:// https:// urls
  expression = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  regex = new RegExp(this.expression);
  // regex for www. (without // before it, or it'd re-link the ones done above)
  expression2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  regex2 = new RegExp(this.expression2);
  
  transform(str: string) : string 
  {
    if (!str) return str;

    var result = str.replace(this.regex, "<a href='$1' target='_blank'>[Link]</a>");
    return result.replace(this.regex2, "$1<a href='http://$2' target='_blank'>[Link]</a>");
  }
}

@Pipe({name: 'linkifynone'})
export class LinkifyNonePipe implements PipeTransform 
{
  // regex for http:// https:// urls
  expression = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  regex = new RegExp(this.expression);
  // regex for www. (without // before it, or it'd re-link the ones done above)
  expression2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  regex2 = new RegExp(this.expression2);
    
  transform(str: string) : string 
  {
    if (!str) return str;
   
    var result = str.replace(this.regex, "…");
    return result.replace(this.regex2, "$1…");
  }
}

@Pipe({name: 'trim'})
export class TrimPipe implements PipeTransform 
{
  transform(value: string, ...args: string[]): string
  {
    if (!value) return value;
    let limit = args.length > 0 ? parseInt(args[0], 10) : 50;
    let trail = args.length > 1 ? args[1] : '…';

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

