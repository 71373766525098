// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sound
{
    width: 300px;
    max-width: 80vw;
    display: inline-block;
    margin: 10px;
    vertical-align: top;
    outline: blue;
    cursor: pointer;
}

.sounds-flex
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sound-stats
{
    display: inline-flex;
    vertical-align: middle;
    line-height: 20px;
}

.stat
{
    min-width: 30px;
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
}

.ad-container
{
    height: 475px;
    width: 100%;
    display: block;
}

.stat-user
{
    min-width: 50px;
    text-align: left;
    margin-left: 0px;
    margin-right: 15px;
    font-size: 16px;
}

.stats-icon
{
    vertical-align: middle;
    font-size: 24px;
}

.spinner
{
    margin:0 auto;
}

.spacer
{
    width: 300px;
    padding: 0 24px;
    margin: 0 10px;
    height: 0px;
}

.overflow {
    position: absolute;
    top: 6px;
    right: -12px;
    display: inline-block;
    vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/app/sounds/sounds.component.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,eAAe;AACnB;;AAEA;;IAEI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;;IAEI,oBAAoB;IACpB,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;;IAEI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;;IAEI,aAAa;IACb,WAAW;IACX,cAAc;AAClB;;AAEA;;IAEI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,YAAY;IACZ,eAAe;IACf,cAAc;IACd,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,qBAAqB;IACrB,sBAAsB;AAC1B","sourcesContent":[".sound\r\n{\r\n    width: 300px;\r\n    max-width: 80vw;\r\n    display: inline-block;\r\n    margin: 10px;\r\n    vertical-align: top;\r\n    outline: blue;\r\n    cursor: pointer;\r\n}\r\n\r\n.sounds-flex\r\n{\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n}\r\n\r\n.sound-stats\r\n{\r\n    display: inline-flex;\r\n    vertical-align: middle;\r\n    line-height: 20px;\r\n}\r\n\r\n.stat\r\n{\r\n    min-width: 30px;\r\n    text-align: left;\r\n    margin-left: 5px;\r\n    margin-right: 5px;\r\n}\r\n\r\n.ad-container\r\n{\r\n    height: 475px;\r\n    width: 100%;\r\n    display: block;\r\n}\r\n\r\n.stat-user\r\n{\r\n    min-width: 50px;\r\n    text-align: left;\r\n    margin-left: 0px;\r\n    margin-right: 15px;\r\n    font-size: 16px;\r\n}\r\n\r\n.stats-icon\r\n{\r\n    vertical-align: middle;\r\n    font-size: 24px;\r\n}\r\n\r\n.spinner\r\n{\r\n    margin:0 auto;\r\n}\r\n\r\n.spacer\r\n{\r\n    width: 300px;\r\n    padding: 0 24px;\r\n    margin: 0 10px;\r\n    height: 0px;\r\n}\r\n\r\n.overflow {\r\n    position: absolute;\r\n    top: 6px;\r\n    right: -12px;\r\n    display: inline-block;\r\n    vertical-align: middle;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
