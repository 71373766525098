import { Component, OnInit, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params }   from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ConfirmDialog } from '../confirm.dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
//import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

// services
import { MarketService } from '../market.service';
import { MarketAuth } from '../marketauth.service';
import { MetaService } from '../meta.service';
//import { MessagingService } from '../messaging.service';
//import { CookieService } from 'ngx-cookie';

// objects
import { Sound } from '../sound';

@Component({
  selector: 'app-sounds',
  templateUrl: './sounds.component.html',
  styleUrls: ['./sounds.component.css']
})

export class SoundsComponent implements OnInit 
{
  // input can be latest sounds (no input), or userId for user sounds, or tag for tag sounds
  @Input() user: string = "";
  @Input() category: string = "";

  // sounds  
  sounds: Sound[] = [];          // sounds displayed on page
  working = false;               // displays working progress bar if true
  showAd = false;                // display advertising

  // query paging used only by this class
  private offset = 0;      // current offset requested for query
  private limit = 48;      // total items requested for query
  private requested = -1;  // last inclusive offset requested from server to prevent dup requests
  private max = 1000;      // maximum sounds to receive from server
  private min = 26;        // minimum sounds needed to allow another load (best mixes always returns 25)

  constructor(protected marketService: MarketService,
              protected marketAuth: MarketAuth,
              protected metaService: MetaService,
              protected router: Router,
              protected route: ActivatedRoute,
              private sanitizer: DomSanitizer,              
              private cdRef: ChangeDetectorRef,
              private dialog: MatDialog)
  {    
  }

  public ngOnInit(): void 
  {            
    let user = this.marketAuth.getUser();
    let subscriber = user && user.isSubscriber;
    this.showAd = !subscriber;    
    console.log("Initialized sounds for category: ", this.category, " and user: ", this.user);
    
    // get the sounds
    this.getSounds();
  }

  // call to reset internals for brand new query
  protected reset()
  {
    this.offset = 0;
    this.limit = 48;
    this.requested = -1;
    this.sounds = [];
    this.working = false;
  }

  // request next page of sounds
  private getSounds(): void
  {
    // increase offset if we already have sounds
    if (this.sounds.length > 0)
    {
      this.offset += this.limit;
    }

    // verify offset and request
    if (this.offset + this.limit > this.max)
    {
      console.warn("Attempted to request more than " + this.max + " sounds");
      return; // server limits to 1,000 sounds
    }
    if (this.offset <= this.requested)
    {
      console.warn("Attempted to request data offset: " + this.offset + " when already requested up to: " + this.requested);
      return;
    }

    this.working = true;
    this.requested = this.offset + this.limit - 1; // set requested up to last item to be received (one less than next offset)

    console.info("Requesting offset: " + this.offset + " limit: " + this.limit + " req: " + this.requested);
    //this.marketService.getSounds(this.limit, this.offset)
    this.runQuery(this.limit, this.offset).then(sounds => this.addSounds(sounds))
                                       .catch(err => this.handleError(err));

    // have to do this to show progress bar after receiving sounds (why?)
    //this.cdRef.markForCheck();
    //this.cdRef.detectChanges();
  }

  // call the appropriate query function to get sounds based on input param
  private runQuery(limit: number, offset : number): Promise<Sound[]>
  {
    console.log("Querying sounds for category: ", this.category, " and user: ", this.user, " with limit: ", limit, " and offset: ", offset);
    // default implement runs queries for recent sounds
    if (this.category.length > 0)
    {
      // tag sounds
      return this.marketService.getSoundsByTag(this.category, limit, offset);
    }
    else if (this.user.length > 0)
    {
      if (this.user == "feed")
      {
        // user feed
        return this.marketService.getUserFeed(limit, offset);
      }
      else 
      {
        // user sounds
        return this.marketService.getSoundsByUserId(this.user, limit, offset);
      }
    }
    else
    {
      // latest sounds
      return this.marketService.getSounds(limit, offset);
    }
  }

  // received new sounds from server
  private addSounds(sounds : Sound[]): void
  {
    this.working = false;
    console.info("Received sounds: ", sounds); 

    if (sounds == undefined || sounds.length == 0) // no sounds returned from server
    {
      console.info("No more sounds available from server");
      this.offset = this.max;
    }
    else
    {
      // insert ad if we have more than 10 sounds and not a subscriber
      if (this.showAd && sounds.length >= 10)
      {        
          // insert an empty sound into the middle of our array to display an ad
          // note: we can't use the last item as that is checked for loading more sounds...
          var index = sounds.length / 2;
          sounds.splice(index,0,new Sound());
          //console.log("Inserted tile ad into position: ", index);        
      }

      if (this.sounds.length == 0) // first load
      {
        this.sounds = sounds;

        // set the meta image to be the first sound
        const image = sounds.length > 0 ? sounds[0].ImageUrl : "/assets/images/white-noise-market.png";
        this.metaService.updateImage(image);
      }
      else
      {        
        // replace the entire array so update is detected
        this.sounds = [...this.sounds, ...sounds];
        // manually call detect changes
        this.cdRef.detectChanges();
      }

      // update available boolean
      //this.available = this.sounds.length > 0;
      console.log("Updated sounds:", this.sounds.length, "last item:", this.sounds[this.sounds.length-1].Label); // , "available:", this.available);
    }

    // have to do this in order to apply lazy load to new items and toggle working progress bar
    //this.cdRef.markForCheck();
    //this.cdRef.detectChanges();
  }

  onImageStateChange(event: any, sound: Sound): void
  {
    switch (event.reason) {
      // succeed or fail doesn't matter, check if we can load more sounds
      case 'loading-succeeded':
      case 'loading-failed':
        this.lazyLoaded(sound)
        break;
    }
  }

  // called from html
  public lazyLoaded(sound: Sound): void
  {
    //console.log("Lazy loaded sound: ", sound.Label, " with offset: ", this.offset, " and min: ", this.min, " and last: ", this.sounds[this.sounds.length - 1])
    
    // check if we have the minimum number of sounds and have loaded the last sound
    if (this.sounds.length > this.min && sound == this.sounds[this.sounds.length - 1])
    {
      console.log("Loaded bottom sounds so rquesting more sounds from server.")
      // request more sounds
      this.getSounds();
    }
  }

  soundLink(sound: Sound) : string
  {
    return "/sound/" + sound.Slug + "?id=" + sound.Uid;
  }

  safeSoundLink(sound: Sound) : SafeUrl
  {
     // create a URL to the sound
     return this.sanitizer.bypassSecurityTrustResourceUrl( this.soundLink(sound) );
  }

  public gotoSound(sound : Sound): void
  {
    console.log("Navigating to sound " + sound.Label + " with uid " + sound.Uid);
    var url = this.soundLink(sound); // "/sound/" + sound.Slug + "?id=" + sound.Uid;

    if (this.offset > 0)
    {
      // HACK: because router breaks when pulling more sounds into the array
      // no idea why but clicking on the new tiles will break router and add both
      // sound-details and leave existing tiles component
      window.location.assign(url);
    }
    else
    {
      this.router.navigateByUrl(url);
    }
  }

  public onDeleteSound(sound: Sound): void
  {
    let options = {
      data: {
          title: "Warning",
          message: 
          `Deleted sounds cannot be uploaded again. Are you sure you want to delete this sound? 
          If you would rather change any details of the sound please contact support.`,
        }
    };

    this.dialog.open(ConfirmDialog, options)
              .afterClosed()
              .subscribe(result => {
                    if (!result) return;

                    // delete the sound after confirmation
                    this.marketService.deleteSound(sound)
                      .then(result => {
                        if (result) {
                          // if deleted then remove the sound from the list
                          console.log("Deleted sound: ", sound.Uid);
                          this.sounds = this.sounds.filter(s => s.Uid != sound.Uid);
                        }
                      })
                      .catch(err => console.error("Failed to delete sound: ", err));
                });
  }

  public isMyFeed(): boolean
  {
    // mobile clients offer delete if the feed is the user's sounds
    let authUser = this.marketAuth.getUser();
    if (this.user && this.user.length > 0)
    {
      return this.user == authUser.userId;
    }

    return false;
  }

  private handleError(error : any) : void 
  {
    console.error("Error loading sounds: ", error);
    // do not permit anymore requests
    this.working = false;
    this.offset = this.max;
  }
}
