// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.content
{
  padding: 20px;
}

.padding 
{
  padding-left: 10pt;
  padding-right: 10pt;
}

hr
{ 
  margin: 25px;
  height: 1px;
  color: #ccc;
  background-color: #ccc;
  border: none;
}

*
{
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

`, "",{"version":3,"sources":["webpack://./src/app/content.css"],"names":[],"mappings":";AACA;;EAEE,aAAa;AACf;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;EACZ,WAAW;EACX,WAAW;EACX,sBAAsB;EACtB,YAAY;AACd;;AAEA;;EAEE,+CAA+C;AACjD","sourcesContent":["\r\n.content\r\n{\r\n  padding: 20px;\r\n}\r\n\r\n.padding \r\n{\r\n  padding-left: 10pt;\r\n  padding-right: 10pt;\r\n}\r\n\r\nhr\r\n{ \r\n  margin: 25px;\r\n  height: 1px;\r\n  color: #ccc;\r\n  background-color: #ccc;\r\n  border: none;\r\n}\r\n\r\n*\r\n{\r\n  font-family: Roboto,\"Helvetica Neue\",sans-serif;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
