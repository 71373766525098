import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService, CookieOptions } from 'ngx-cookie';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LoginDialog } from './login.dialog';
import { environment } from '../environments/environment';

import { User } from './user';
import { MessagingService } from './messaging.service';

// documentation refs:
// https://github.com/angular/angularfire/blob/master/docs/auth.md
// https://firebase.google.com/docs/auth/web/start

// Stub class for implementing authentication backed by firebase
@Injectable()
export class MarketAuth implements OnDestroy
{
    // active user ( new method: see https://blog.angular-university.io/how-to-build-angular2-apps-using-rxjs-observable-data-services-pitfalls-to-avoid/ )
    private _user: BehaviorSubject<User> = new BehaviorSubject(null);
    public readonly user: Observable<User> = this._user.asObservable();
    public getUser() : Readonly<User> { return this._user.getValue(); }
    private marketToken: string;

    constructor(
        private http: HttpClient,
        private fireAuth: AngularFireAuth,
        private cookieService: CookieService,
        private messagingService: MessagingService,
        private dialog: MatDialog) {

        // check cookie service from cross subdomain activation
        if (this.isAuthenticated() == false)
        {
            let token = this.cookieService.get("token");
            //console.log("Checking cookie token: " + token);
            if (token && token.length > 0) {
                console.log("Initializing token from cookie: " + token);
                this.setToken(token);
            }
        }
    }

    ngOnDestroy(): void {
    }

    showLogin() {
        this.dialog.open(LoginDialog).afterClosed().subscribe((result: any) =>
        {
            // firebase email logins return our market token
            console.log('Login Dialog Returned:', result);
        });
    }

    async login(service: string, serviceData: any) : Promise<any> {
        if (service === 'google') {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope("email");
            provider.addScope("profile");

            return this.loginWithProvider(provider, false);

        } else if (service === 'facebook') {
            const provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope("email");
            provider.addScope("public_profile");
            provider.setCustomParameters({
                auth_type: 'rerequest'
            });

            return this.loginWithProvider(provider, false);

        } else if (service === 'email') {
            return this.loginWithEmail(serviceData);
        } else {
            // unsupported
            console.log("Unsupported login service: " + service);
            return Promise.reject("Unknown service provider!");
        }
    }

    logoff() : void {

        this.fireAuth.signOut().then(() =>
        {
            // clear market token to complete sign out
            this.marketToken = "";
            this._user.next(null);
            this.updateLoginState();
        });
    }

    deleteUser() : void {
        this.fireAuth.currentUser.then(user => {
            user?.delete();
            this.logoff();
        });
    }

    isAuthenticated() : boolean {
        return !!this.getToken();
    }

    getToken() : string {
        return this.marketToken;
    }

    setToken(token: string) : void {
        this.marketToken = token;
        this.updateLoginState();
    }

    headers() : any {
        return { headers: this.authHeaders()};
    }

    authHeaders() : HttpHeaders {
        return new HttpHeaders().set(
            "Authorization", "Bearer " + this.getToken()
        );
    }

    updateLoginState()
    {
        let options : CookieOptions = { path: "/", domain: location.hostname };
        if (this.isAuthenticated() == false) {
            this.cookieService.remove("token", options);
            this._user.next(null);
        } else {
            // update status to get user object which includes name, avatar, and if admin
            this.getStatus().then(status => {
                //console.log("Status response: " + JSON.stringify(status));
                this.cookieService.put("token", this.getToken(), options);
                this._user.next(status)
            });
        }
    }

    public createEmail(serviceData: any) : Promise<any> {
        return this.fireAuth.createUserWithEmailAndPassword(serviceData.email, serviceData.password);
    }

    public resetEmail(serviceData: any) : Promise<any> {
        return this.fireAuth.sendPasswordResetEmail(serviceData.email);
    }

    private getDisplayName(firstName: string, lastName: string) : string {
        let displayName = "";
        
        if (firstName.length > 0) {
            displayName = firstName;
        }
        
        if (lastName.length > 0) {
            if (displayName.length > 0) {
                displayName += " ";
            }
            displayName += lastName;
        }
        return displayName;
    }

    private loginWithProvider(provider: firebase.auth.AuthProvider, verifyEmail: boolean) : Promise<any> {

        return new Promise(async (resolve, reject) => {

            this.fireAuth.signInWithPopup(provider).then((creds: any) => {

                if (!creds.user.email) {
                    let err = {
                        message: "An email address is required to create an account. Please grant permission to share the email address or choose a different provider that is associated with an email address."
                    };
                    // deleting the user forces reauth and cleans up the blank firebase account
                    // using auth_type custom param didn't appear to work
                    this.deleteUser();
                    reject(err);
                    return;
                }

                if (verifyEmail === true) {
                    if (!creds.user.emailVerified) {
                        creds.user.sendEmailVerification();
                        let err = {
                            message: "A verification email has been sent to the address provided. Please check your email and click the verify link to finish creating your account."
                        };
                        reject(err);
                        return;
                    }
                }

                creds.user.getIdToken().then((idToken: any) => {

                    let userData = this.createUserData();
                    userData['access_token'] = idToken;
                    userData["app"] = "web"; // setting to "web" will tell server to skip over the http header checks used by mobile app

                    if (creds.credential.providerId === 'google.com') {
                        let profile = creds.additionalUserInfo.profile;
                        
                        let firstName = profile['given_name'];
                        if (firstName == null || firstName.length == 0) {
                            firstName = "";
                        }
                        let lastName = profile['family_name'];
                        if (lastName == null || lastName.length == 0) {
                            lastName = "";
                        }

                        userData['provider'] = 'google.com';
                        userData['gid'] = profile['id'];
                        userData['uid'] = creds.user.uid;
                        userData['email'] = creds.user.email;
                        userData['firstname'] = firstName;
                        userData['lastname'] = lastName;
                        userData['fullname'] = this.getDisplayName(firstName, lastName);
                        userData['avatar'] = creds.user.photoURL;

                    } else if (creds.credential.providerId === 'facebook.com') {
                        let profile = creds.additionalUserInfo.profile;

                        let firstName = profile['first_name'];
                        if (firstName == null || firstName.length == 0) {
                            firstName = "";
                        }
                        let lastName = profile['last_name'];
                        if (lastName == null || lastName.length == 0) {
                            lastName = "";
                        }

                        userData['provider'] = 'facebook.com';
                        userData['fid'] = profile['id'];
                        userData['uid'] = creds.user.uid;
                        userData['email'] = creds.user.email;
                        userData['firstname'] = firstName;
                        userData['lastname'] = lastName;
                        userData['fullname'] = this.getDisplayName(firstName, lastName);
                        userData['avatar'] = creds.user.photoURL;
                    }

                    this.getBearerToken(userData).then(res => {
                        let token = res['token'];
                        this.setToken(token);
                        resolve(token);
                    }).catch((err: any) => {
                        reject(err);
                    });

                // getIdToken
                }).catch((err: any) => {
                    reject(err);
                });
            // signInWithPopup
            }).catch(err => {
                reject(err);
            });
        });
    }

    private loginWithEmail(serviceData: any) : Promise<any> {

        return new Promise(async (resolve, reject) => {
            this.fireAuth.signInWithEmailAndPassword(serviceData.email, serviceData.password).then((creds: any) => {

                creds.user.getIdToken().then((idToken: any) => {
                    let userData = this.createUserData();
                    userData['provider'] = "email";
                    userData['uid'] = creds.user.uid;
                    userData['email'] = serviceData.email;
                    userData["app"] = "web"; // setting to "web" will tell server to skip over the http header checks used by mobile app
                    userData['access_token'] = idToken;

                    this.getBearerToken(userData).then(res => {
                        let token = res['token'];
                        this.setToken(token);
                        resolve(token);
                    }).catch(err => {
                        reject(err);
                    });
                }).catch((err: any) => {
                    reject(err);
                });
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    private async getBearerToken(userData: any) : Promise<any> {
        const url = `${environment.host}/auth/firebase/mobile`;
        return this.http.post(url, userData).toPromise();
    }

    private async getStatus(): Promise<any> {
        const url = environment.host  + '/api/status';
        return this.http.get(url, this.headers()).toPromise();
    }

    private createUserData() : any
    {
        // pulled from article (althought not completely accurate its pretty compatible)
        // see:  https://stackoverflow.com/questions/1091372/getting-the-clients-timezone-in-javascript
        var date = new Date();
        var timezone = -date.getTimezoneOffset() / 60;
        var userData = { "timezone": timezone };
        //console.log("Logging in with timezone: " + timezone);

        var lang = this.language();
        if (lang != null)
        {
            //console.log("Logging in with language: " + lang);
            userData["language"] = lang;
        }

        // create user data for our timezone and web token
        // note: now doing this via user config api call
        var token = this.messagingService.currentToken.getValue();
        if (token != null) {
            userData['push_web'] = token;
            //console.log("Logging in with push web token: " + token);
        }

        return userData;
    }

    // helper function to get language (see https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference)
    private language(): string {
        var nav = window.navigator;
        var browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

        // support for HTML 5.1 "navigator.languages"
        if (Array.isArray(nav.languages)) {
            for (var i = 0; i < nav.languages.length; i++) {
                var language = nav.languages[i];
                if (language && language.length) {
                    console.log("Found language in languages", language);
                    return language;
                }
            }
        }

        // support for other well known properties in browsers
        for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
            var key = browserLanguagePropertyKeys[i];
            var language = nav[key];
            if (language && language.length) {
                console.log("Found language in field", key, "language", language);
                return language;
            }
        }

        return null;
    };
}