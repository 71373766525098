import { Component } from '@angular/core';

import { MetaService } from './meta.service';

@Component({
   selector: 'market-terms',
   templateUrl: './terms.component.html',
   styleUrls: [ './content.css' ],
})
export class TermsComponent
{
  constructor(private metaService: MetaService) 
  {
    const title = "Terms and Conditions";
    const description = "Terms and Conditions for using the White Noise Market";
    const image = "/assets/images/white-noise-market.png";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }
}
