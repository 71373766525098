import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { User } from './user';
import { MarketService } from './market.service';
import { MetaService } from './meta.service';
import { Router, ActivatedRoute, Params }   from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'market-users',
  templateUrl: './users.component.html',
  styleUrls: [ './users.component.css' ],
})

// tiles should be used as a base class to handle queries and paging
export class UsersComponent implements OnInit
{
  // information displayed in tiles html
  title = "Top Users";           // optional title displayed at top of page
  subtitle = "Highest Ranking User Contributors";  // optional subtitle displayed at top of page
  avatar = "/assets/images/top-users.jpg";         // optional avatar displayed at top of page
  description: string;           // optional description displayed at top of page
  profile : any;                 // optional user profile for displaying stats
  users: User[] = [];            // users displayed on page 
  working = false;               // displays working progress bar if true

  // search bar support
  sort = "";
  showSearchBar = false;

  // query paging used only by this class
  private offset = 0;      // current offset requested for query
  private limit = 50;      // total items requested for query
  private requested = -1;  // last inclusive offset requested from server to prevent dup requests
  private max = 1000;      // maximum users to receive from server
  private min = 26;        // minimum users needed to allow another load (best mixes always returns 25)

  // inherited class use this after constructor
  public construct(): void // called during constuction
  {
  }
  public init(): void // called during ngOnInit
  {
     // request first page of users by default
     this.getUsers();
  }
  // implement the query for limit and offset in the inherited class
  public query(limit: number, offset : number): Promise<User[]>
  {
    // default implement runs queries for users
    return this.marketService.getUsers(this.sort);
  }
  // call to reset internals for brand new query
  protected reset()
  {
    this.offset = 0;
    this.limit = 50;
    this.requested = -1;
    this.users = [];
    this.working = false;
  }

  constructor(protected marketService: MarketService,
              protected metaService: MetaService,
              protected router: Router,
              protected route: ActivatedRoute,
              private elemRef: ElementRef,
              private cdRef: ChangeDetectorRef,
              private sanitizer: DomSanitizer)
  {
    //console.log("UsersComponent constructed")
    this.construct();
  } 

  public ngOnInit(): void
  {
    //console.log("UsersComponent ngOnInit")

    // allow sub-class chance to setup
    this.init();
  }

  // request next page of sounds
  protected getUsers(): void
  {
    // increase offset if we already have sounds
    if (this.users.length > 0) 
    {
      this.offset += this.limit;
    }

    // verify offset and request
    if (this.offset + this.limit > this.max) 
    {
      console.warn("Attempted to request more than " + this.max + " users");
      return; // server limits to 1,000 sounds
    }
    if (this.offset <= this.requested)
    {
      console.warn("Attempted to request data offset: " + this.offset + " when already requested up to: " + this.requested);
      return;
    }

    this.working = true;
    this.requested = this.offset + this.limit - 1; // set requested up to last item to be received (one less than next offset)

    console.info("Requesting offset: " + this.offset + " limit: " + this.limit + " req: " + this.requested);   
    //this.marketService.getSounds(this.limit, this.offset)
    this.query(this.limit, this.offset).then(users => this.addUsers(users))
                                       .catch(err => this.handleError(err));

    // have to do this to show progress bar after receiving data (why?)
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
    
  }

  // received new data from server
  private addUsers(users : User[]): void
  {
    this.working = false;

    if (users.length == 0) // no data returned from server
    {      
      console.info("No more users available from server");
      this.offset = this.max;
    }
    else if (this.users.length == 0) // first load
    {
      this.users = users;
      console.log("Updated users: " + this.users.length);

      // set the meta image to be the first sound
      const image = users.length > 0 ? users[0].avatarUrl : "/assets/images/white-noise-market.png";
      this.metaService.updateImage(image);
    }
    else
    {
      Array.prototype.push.apply(this.users, users);
      console.log("Updated users: " + this.users.length);      
    }

    // have to do this in order to apply lazy load to new items
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  // called from html
  public lazyLoaded(user: User): void
  {
    // check if we have the minimum number of data and have loaded the last sound
    if (this.users.length > this.min && user == this.users[this.users.length - 1])
    {
      // request more data
      this.getUsers();
    }
  }

  userLink(user: User) : string
  {
    return "/user/" + user.userId;
  }

  safeUserLink(user: User) : SafeUrl
  {
     // create a URL to the user
     return this.sanitizer.bypassSecurityTrustResourceUrl( this.userLink(user) );
  }

  public gotoUser(user : User): void
  {
    console.log("Navigate to user " + user.displayName + " with uid " + user.userId);
    var url = this.userLink(user); // "/sound/" + sound.Slug + "?id=" + sound.Uid;

    if (this.offset > 0)
    {
      // HACK: because router breaks when pulling more sounds into the array
      // no idea why but clicking on the new tiles will break router and add both
      // sound-details and leave existing tiles component
      window.location.assign(url);
    }
    else
    {
      this.router.navigateByUrl(url);
    }
  }

  avatarError(image : any): void
  {
    //console.warn("Failed to load sound avatar");
    image.onerror = null;
    image.src = "/assets/images/default_user.jpg";
  }

  private handleError(error : any) : void
  {
    console.info("Server had error on sound request.");
    // do not permit anymore requests
    this.working = false;
    this.offset = this.max;
  }
}
