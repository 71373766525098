import { Component } from '@angular/core';
import { Params }   from '@angular/router';

import { Sound } from './sound';
import { TilesComponent } from './tiles.component';

@Component({
  selector: 'market-user',
  templateUrl: './tiles.component.html',
  styleUrls: [ './tiles.component.css' ],
})

export class UserComponent extends TilesComponent
{
    userId : string;    

    public construct()
    { 
        const title = "White Noise Sounds by User - White Noise Market";   
        const description = "Download user created sounds to White Noise for free.";
        const image = "/assets/images/white-noise-market.png";

        // update title and meta data
        this.metaService.updateMetadata(title, description, image);
    }

    // called after ngInit
    public init()
    {
        this.route.params.subscribe((params: Params) => { 
            this.setUser(params['user'] as string) ;
        });
    }

    // implement the query for limit and offset in the inherited class
    public query(limit: number, offset : number): Promise<Sound[]>
    {
        // default implement runs queries for user sounds and sets up title based on user display name and then passes back the sounds to base class
        return this.marketService.getSoundsByUser(this.userId, limit, offset).then(response => 
            {   
                //this.setTitle(response.DisplayName);                 
                return response.Sounds;
            });
    }

    private setUser(userId: string)
    {
        console.log("User Component requesting user sounds: " + userId);
        this.userId = userId;

        // setup for following
        this.followTag = userId; // users use the user id for flag
        this.followFlag = 3; // users are type 3
        this.isFollowed = this.messagingService.isSubscribedToTopic(this.followTag);

        // pull in user profile
        this.marketService.getUserProfile(this.userId).then(response => {   
                this.setProfile(response);                 
            });
        
        // pull in sounds for this user
        this.reset();
        this.getSounds();
    }

    private setTitle(user : string)
    {
        this.title = "Sounds by " + user;
        this.subtitle = "Sounds uploaded by " + user + " to the White Noise Market."
        
         // setup meta data
        const title = "White Noise Sounds by " + user + " - White Noise Market";
        const description = "Sounds uploaded by " + user + " to the White Noise Market.  Download and listen to user created sounds with the White Noise App by TMSOFT.";

        // update title and meta data
        this.metaService.updateTitle(title);
        this.metaService.updateDescription(description);
    }

    private setProfile(profile : any)
    {
        console.log("User profile received: " + profile);
        this.profile = profile;
        this.avatar = profile.avatarUrl;
        this.setTitle(profile.displayName);
        // parse user description
        if (this.profile.userDescription)
        {
            this.subtitle = this.profile.userDescription;
        }
        // parse user website
        if (this.profile.userWebsite)
        {
            // web link is what users click and display link is what is displayed
            this.webLink = this.profile.userWebsite;
            this.displayLink = this.profile.userWebsite.replace(/^\/\/|^.*?:(\/\/)?/, ''); // remove protocol information for displaying

            // check if there was no protocol information and add it to web link
            if (this.webLink === this.displayLink)
            {
                this.webLink = "http://" + this.webLink;
            }

            // pretty up display link some more (after the above protocol check)
            this.displayLink = this.displayLink.replace(/\/$/, ""); // remove slash from end
            this.displayLink = this.displayLink.replace(/^(www\.)/,""); // remove www. from beginning
        }
        // parse user badges
        if (this.profile.badges)
        {
            // {"Id":"web","Label":"Web World","ImageUrl":"https://img.tmsoft.com/badges/web.png","Description":"Login using the White Noise Market website"}
            this.marketService.getBadges().then(data => this.parseBadges(data, this.profile.badges)).catch(err => this.handleBadgeError(err));
        }
        /*
        if (profile.stats != null)
        {
            this.description = profile.displayName + "'s Zen Rating: " + profile.stats.zen;
            this.description += " - Incoming Hearts: " + profile.stats.hearts + " Comments: " + profile.stats.comments + " Downloads: " + profile.stats.downloads + " Minutes: " + profile.stats.minutes;
            this.description += " - Outgoing Hearts: " + profile.stats.myHearts + " Comments: " + profile.stats.myComments + " Downloads: " + profile.stats.myDownloads + " Minutes: " + profile.stats.myMinutes;
        }
        */
    }

    private parseBadges(all : any[], badges : string) : void
    {
        if (all == null || all.length == 0 || badges == null || badges.length == 0) return;

        let result = [];
        
        for (var i = 0; i < all.length; ++i) 
        {
            let a : any = all[i];
            // {"Id":"web","Label":"Web World","ImageUrl":"https://img.tmsoft.com/badges/web.png","Description":"Login using the White Noise Market website"}
            if (badges.includes(a.Id))
            {
                // add user badge to front of array as the items at the end are more important badges (tougher to get)
                result.unshift(a);
            }
        }
        
        this.badges = result;
        //console.info("Returning total user badges: " + this.badges.length);
    }

    private handleBadgeError(error : any) : void
    {
      console.info("Server had error requesting badges: " + JSON.stringify(error));
    }
}