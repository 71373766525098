import { Component } from '@angular/core';
import { MetaService } from './meta.service';

@Component({
   selector: 'market-privacy',
   templateUrl: './privacy.component.html',
   styleUrls: [ './content.css' ],
})

export class PrivacyComponent
{
  constructor(private metaService: MetaService) 
  {
    const title = "Privacy Policy";
    const description = "Privacy policy for the White Noise Market";
    const image = "/assets/images/white-noise-market.png";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }
}
