import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Sound } from './sound';
import { MarketService } from './market.service';
import { MessagingService } from './messaging.service';
import { CookieService } from 'ngx-cookie';
import { MetaService } from './meta.service';
import { Router, ActivatedRoute, Params }   from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MarketAuth } from './marketauth.service';

@Component({
  selector: 'market-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: [ './tiles.component.css' ],
})

// tiles should be used as a base class to handle queries and paging
export class TilesComponent implements OnInit
{
  // information displayed in tiles html
  title = "";                    // optional title displayed at top of page
  subtitle: string;              // optional subtitle displayed at top of page
  avatar : string;               // optional avatar displayed at top of page
  description: string;           // optional description displayed at top of page
  webLink : string;              // optional web link to follow subtitle
  displayLink : string;          // optional web link for displaying (requires webLink)
  profile : any;                 // optional user profile for displaying stats
  badges : any[];                // optional array of badges from user profile
  sounds: Sound[] = [];          // sounds displayed on page
  working = false;               // displays working progress bar if true
  //available = false;           // sound data available - sound.length > 0 (required because sounds.length check wouldn't work for production builds :/)

  // following / flag support
  followTag : string;            // tag to follow when hearting a category or user
  followFlag : number;           // flag type either 2 or 3 (category or user)
  isFollowed = false;            // true if subscribed to topic (local check)

  // search bar support
  search = "";
  showSearchBar = false;

  // query paging used only by this class
  private offset = 0;      // current offset requested for query
  private limit = 48;      // total items requested for query
  private requested = -1;  // last inclusive offset requested from server to prevent dup requests
  private max = 1000;      // maximum sounds to receive from server
  private min = 26;        // minimum sounds needed to allow another load (best mixes always returns 25)

  // inherited class use this after constructor
  public construct(): void // called during constuction
  {
  }
  public init(): void // called during ngOnInit
  {
     // request first page of sounds by default
     this.getSounds();
  }
  // implement the query for limit and offset in the inherited class
  public query(limit: number, offset : number): Promise<Sound[]>
  {
    // default implement runs queries for recent sounds
    return this.marketService.getSounds(limit, offset);
  }
  // call to reset internals for brand new query
  protected reset()
  {
    this.offset = 0;
    this.limit = 48;
    this.requested = -1;
    this.sounds = [];
    this.working = false;
    //this.available = false;
  }

  constructor(protected marketService: MarketService,
              protected marketAuth: MarketAuth,
              protected metaService: MetaService,
              protected messagingService: MessagingService,
              protected cookies: CookieService,
              protected router: Router,
              protected route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private snackBar: MatSnackBar,
              private cdRef: ChangeDetectorRef)
  {
    //console.log("TilesComponent constructed")
    this.construct();
  }

  public ngOnInit(): void {
    // allow sub-class chance to setup
    this.init();
  }

  // request next page of sounds
  protected getSounds(): void
  {
    // increase offset if we already have sounds
    if (this.sounds.length > 0)
    {
      this.offset += this.limit;
    }

    // verify offset and request
    if (this.offset + this.limit > this.max)
    {
      console.warn("Attempted to request more than " + this.max + " sounds");
      return; // server limits to 1,000 sounds
    }
    if (this.offset <= this.requested)
    {
      console.warn("Attempted to request data offset: " + this.offset + " when already requested up to: " + this.requested);
      return;
    }

    this.working = true;
    this.requested = this.offset + this.limit - 1; // set requested up to last item to be received (one less than next offset)

    console.info("Requesting offset: " + this.offset + " limit: " + this.limit + " req: " + this.requested);
    //this.marketService.getSounds(this.limit, this.offset)
    this.query(this.limit, this.offset).then(sounds => this.addSounds(sounds))
                                       .catch(err => this.handleError(err));

    // have to do this to show progress bar after receiving sounds (why?)
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  // received new sounds from server
  private addSounds(sounds : Sound[]): void
  {
    this.working = false;

    if (sounds.length == 0) // no sounds returned from server
    {
      console.info("No more sounds available from server");
      this.offset = this.max;
    }
    else
    {
      // insert ad if we have more than 10 sounds and not a subscriber
      if (sounds.length >= 10)
      {
        let user = this.marketAuth.getUser();
        let subscriber = user && user.isSubscriber;
        if (!subscriber)
        {
          // insert an empty sound into the middle of our array to display an ad
          // note: we can't use the last item as that is checked for loading more sounds...
          var index = sounds.length / 2;
          sounds.splice(index,0,new Sound());
          //console.log("Inserted tile ad into position: ", index);
        }
        //else
        //{
        //  console.log("Hiding google tile ad for user " + JSON.stringify(user));
        //}
      }

      if (this.sounds.length == 0) // first load
      {
        this.sounds = sounds;

        // set the meta image to be the first sound
        const image = sounds.length > 0 ? sounds[0].ImageUrl : "/assets/images/white-noise-market.png";
        this.metaService.updateImage(image);
      }
      else
      {
        // add to array
        Array.prototype.push.apply(this.sounds, sounds);
      }

      // update available boolean
      //this.available = this.sounds.length > 0;
      console.log("Updated sounds:", this.sounds.length); // , "available:", this.available);
    }

    // have to do this in order to apply lazy load to new items and toggle working progress bar
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  onImageStateChange(event: any, sound: Sound): void
  {
    switch (event.reason) {
      // succeed or fail doesn't matter, check if we can load more sounds
      case 'loading-succeeded':
      case 'loading-failed':
        this.lazyLoaded(sound)
        break;
    }
  }

  // called from html
  public lazyLoaded(sound: Sound): void
  {
    //console.log("Lazy loaded sound: ", sound.Label, " with offset: ", this.offset, " and min: ", this.min, " and last: ", this.sounds[this.sounds.length - 1])
    
      // check if we have the minimum number of sounds and have loaded the last sound
    if (this.sounds.length > this.min && sound == this.sounds[this.sounds.length - 1])
    {
      console.log("Loaded bottom sounds so rquesting more sounds from server.")
      // request more sounds
      this.getSounds();
    }
  }

  soundLink(sound: Sound) : string
  {
    return "/sound/" + sound.Slug + "?id=" + sound.Uid;
  }

  safeSoundLink(sound: Sound) : SafeUrl
  {
     // create a URL to the sound
     return this.sanitizer.bypassSecurityTrustResourceUrl( this.soundLink(sound) );
  }

  public gotoSound(sound : Sound): void
  {
    console.log("Navigating to sound " + sound.Label + " with uid " + sound.Uid);
    var url = this.soundLink(sound); // "/sound/" + sound.Slug + "?id=" + sound.Uid;

    if (this.offset > 0)
    {
      // HACK: because router breaks when pulling more sounds into the array
      // no idea why but clicking on the new tiles will break router and add both
      // sound-details and leave existing tiles component
      window.location.assign(url);
    }
    else
    {
      this.router.navigateByUrl(url);
    }
  }

  onFollow(tag: string) {
    // tag in this case can be a user uid if following a user
    if (!tag || tag.length == 0) {
      return;
    }

    console.log("onFollow tag: ", tag);
    if (this.messagingService.isSubscribedToTopic(tag) == false) {
        // follow topic on messaging service
        this.messagingService.subscribeToTopic(tag);
        this.isFollowed = true;
    } else {
      // unfollow topic on messaging service
      this.messagingService.unsubscribeFromTopic(tag);
      this.isFollowed = false;
    }

    // if authenticated, post flag to server
    if (this.marketAuth.isAuthenticated()) {
      this.marketService.setFlag(this.followFlag, tag, "", this.isFollowed)
          .then(result => {
            console.log("Follow flag set: ", result);
          })
          .catch(err => {
            console.error("Error setting follow flag: ", err);
          });
    }
  }

  showMessage(msg : string): void {
      this.snackBar.open(msg, null, { duration: 3000, verticalPosition: "bottom" });
  }

  avatarError(image : any): void {
    //console.warn("Failed to load sound avatar");
    image.onerror = null;
    image.src = "/assets/images/default_user.jpg";
  }

  private handleError(error : any) : void {
    console.error("Error loading sounds: ", error);
    // do not permit anymore requests
    this.working = false;
    this.offset = this.max;
  }
}
