import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.html'
})
export class TermsDialog implements OnInit {

  
  constructor(private dialogRef: MatDialogRef<TermsDialog>)
   { }

  ngOnInit() {
  }

}
