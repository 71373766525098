import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Tag } from './tag';
import { MarketService } from './market.service';
import { Router } from '@angular/router';

@Component({
  selector: 'market-tags',
  templateUrl: './tags.component.html',
  styleUrls: [ './tags.component.css']
})

export class TagsComponent implements OnInit
{
  @Output() navigatingToTag = new EventEmitter();
  @Output() tagsLoaded = new EventEmitter();
  tags: Tag[];

	constructor(private router: Router,
              private marketService: MarketService) { }

  getTags(): void
  {
    this.marketService.getTags().then(tags => {
      this.tags = tags;
      let tagEvent = this.tagsLoaded;
      tagEvent.emit();
      //setTimeout(() => tagEvent.emit(), 3000); // used to test cases where tags may not load immediately
    });
  }

  ngOnInit(): void
  {
    this.getTags();
  }

  gotoTag(tag : Tag): void
  {
    this.navigatingToTag.emit();
    // nagivate to tag's value
    this.router.navigate(['/tag', tag.Value]);
  }

  lazyLoaded(tag : Tag): void
  {
    console.log("Tag image loaded: " + tag.Label);
  }
}


