import { Component } from '@angular/core';
import { MetaService } from './meta.service';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TermsDialog } from './store/terms-dialog/terms-dialog';

@Component({
   selector: 'market-help',
   templateUrl: './help.component.html',
   styleUrls: [ './content.css' ],
})
export class HelpComponent
{
  // should match the route for this component, so #pagelinks work properly
  linkRoot = "help";

  constructor(private metaService: MetaService, private dialog: MatDialog) 
  {
    const title = "Help for White Noise Market";
    const description = "Frequently asked questions and support for the White Noise Market website and app.";
    const image = "/assets/images/white-noise-market.png";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }

  onMP3Terms(): void
  {
    this.dialog.open(TermsDialog);
  }
}
