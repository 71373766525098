import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Location }                 from '@angular/common';

import { Sound } from './sound';
import { MarketService } from './market.service';
import { MarketAuth } from './marketauth.service';

@Component({
  selector: 'market-token',
  templateUrl: './tiles.component.html',
  styleUrls: [ './tiles.component.css' ],
})

export class TokenComponent implements OnInit
{
    title: string;
    working = false;
    sounds: Sound[];
    token: string;
    showSearchBar = false;

    constructor(private marketService: MarketService,
                private marketAuth: MarketAuth,
                private route: ActivatedRoute,
                private location: Location)
    {
    }

    ngOnInit(): void
    {
        // if browser is going directly to search location then set the search bar text to value
        this.token = location.pathname.substring(7);

         // single route param subscribe:
         console.log("Initializing token: " + this.token);
         this.setToken(this.token);
         //this.route.params.switchMap((params: Params) => { this.setToken(params['token']); return null; });
    }

    setToken(token : string)
    {
        console.log("Setting token: " + token);
        this.title = "Set token: " + token;
        if (token === "test")
        {
            this.runTest();
        }
        else if (token === "me")
        {
            this.title = "My token: " + this.marketAuth.getToken();
        }
        else
        {
            return this.marketAuth.setToken(token);
        }
    }

    runTest()
    {
        this.marketService.testServer()
                          .then(result => { this.title = "Result: " + JSON.stringify(result); console.log("Result:", result)})
                          .catch(err => this.handleError(err));
    }

    handleError(error : any) : void
    {
        // on error set an empty array and working to false
        this.title = "Error: " + error;
    }

}
