// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-field
{
    color: #000;
}

.config-container 
{
    display: flex;
    flex-direction: column;
    min-height: 150px;
}
  
.config-container > * 
{
    width: 80%;
    margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/config.dialog.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;AACf;;AAEA;;IAEI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;;IAEI,UAAU;IACV,eAAe;AACnB","sourcesContent":[".value-field\r\n{\r\n    color: #000;\r\n}\r\n\r\n.config-container \r\n{\r\n    display: flex;\r\n    flex-direction: column;\r\n    min-height: 150px;\r\n}\r\n  \r\n.config-container > * \r\n{\r\n    width: 80%;\r\n    margin-top: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
