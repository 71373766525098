import { NgModule }             from '@angular/core';

import {
  RouterModule,
  Routes,
  Router,
  ActivatedRoute,
  NavigationEnd,
}                               from '@angular/router';

import { HomeComponent }        from './home.component';
import { TagComponent }         from './tag.component';
import { SearchComponent }      from './search.component';
import { UserComponent }        from './user.component';
import { MapComponent }        from './map/map.component';
import { SoundDetailComponent } from './sound-detail.component';
import { UsersComponent }       from './users.component';
import { ChatterComponent }     from './chatter.component';
import { NotificationsComponent } from './notifications.component';
import { AvatarsComponent }       from './avatars.component';
import { BadgesComponent }        from './badges.component';

// static content
import { HelpComponent }        from './help.component';
import { DownloadComponent }    from './download.component';
import { TermsComponent }       from './terms.component';
import { PrivacyComponent }     from './privacy.component';

// mp3 shopping cart
import { CatalogComponent }     from './store/catalog/catalog.component';
import { CheckoutComponent }    from './store/cart/checkout.component';
import { OrdersComponent }      from './store/orders/orders.component';

// account mgt
import { DeleteComponent } from './account/delete.component';

// debugging
import { TokenComponent } from './token.component';

// rxjs
import { filter } from 'rxjs/operators';

// declare google analytics global var
declare var ga : any;

const routes: Routes = [
  { path: '',                         component: HomeComponent },
  { path: 'sound/:slug',              component: SoundDetailComponent },
  { path: 'tag/:tag',                 component: TagComponent },
  { path: 'search',                   component: SearchComponent },
  { path: 'search/:search',           component: SearchComponent },
  { path: 'user/:user',               component: UserComponent },
  { path: 'app',                      component: DownloadComponent },
  { path: 'chatter',                  component: ChatterComponent },
  { path: 'map',                      component: MapComponent },
  { path: 'users',                    component: UsersComponent },
  { path: 'notifications',            component: NotificationsComponent },
  { path: 'avatars',                  component: AvatarsComponent },
  { path: 'badges',                   component: BadgesComponent },
  { path: 'help',                     component: HelpComponent },
  { path: 'terms',                    component: TermsComponent },
  { path: 'privacy',                  component: PrivacyComponent },
  { path: 'token/:token',             component: TokenComponent },
  { path: 'store',                    component: CatalogComponent},
  { path: 'store/checkout',           component: CheckoutComponent },
  { path: 'store/orders',             component: OrdersComponent },
  { path: 'store/orders/:orderId',    component: OrdersComponent },
  { path: 'account/delete',           component: DeleteComponent },
  //  { path: 'cbauth/:provider',     component: CallbackComponent },

  // redirect to home page (ex: /catalog or /categories from old site)
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule
{

  constructor(router: Router, activatedRoute: ActivatedRoute)
  {
    // monitor page changes through NavigationEnd events
    const navEnd = router.events.pipe(filter(e => e instanceof NavigationEnd));
    navEnd.subscribe((event) => {

        // send google analytics page view
        var url = (event as NavigationEnd).urlAfterRedirects;
        if (url)
        {
          //console.log("Google analytics: " + url);
          ga('send', 'pageview', url);

          // scroll to the top (most pages do this automatically due to loading content async, but static pages do not)
          if (url.indexOf('#') < 0)
          {
            let cdkScrollable = document.querySelector('[cdkscrollable]');
            if (cdkScrollable) cdkScrollable.scrollTop = 0;
              else window.scrollTo(0,0);
          }
        }
    });
  }

}
