import { Component, OnInit } from '@angular/core';
import { Router }   from '@angular/router';

import { MarketService } from './market.service';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

// data
import { Note } from './note';

@Component({
  selector: 'market-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: [ './tables.css' ],
})

export class NotificationsComponent implements OnInit
{
    title = "Notifications";
    working = false;

    // data table
    displayedColumns = ['Image', 'Note'];
    database = new NoteDatabase();
    dataSource: NoteDataSource | null;
  
    constructor(private marketService: MarketService,
                private router: Router,
                private dialog: MatDialog)
    {}

    ngOnInit(): void
    {
      this.working = true;
      this.dataSource = new NoteDataSource(this.database);
      this.marketService.getUserNotifications().then(data => this.setData(data)).catch(err => this.setError(err));
    }

    setData(data: Note[])
    {
      this.working = false;
      this.database.dataChange.next(data);
    }
  
    setError(err: any)
    {
      console.info("Error during query!");
      this.working = false;
    }

    getMarketWebsiteLink(row: Note): string
    {
      let url = row.Url;
      if(!url) return "";
      url = url.replace("whitenoisemarket://", "//whitenoisemarket.com");
      return url;
    }

    openNotification(url: string)
    {
      url = url.replace("whitenoisemarket:///", "/");
      url = url.replace("whitenoisemarket://", "/");
      this.router.navigateByUrl(url);
    }
}

export class NoteDatabase 
{
  dataChange: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>([]);
  get data(): Note[] { return this.dataChange.value; }
  
  constructor() {}
}

export class NoteDataSource extends DataSource<any> 
{
  constructor(private _database: NoteDatabase) 
  {
    super();
  }

  connect(): Observable<Note[]> 
  {
    return this._database.dataChange;
  }

  disconnect() {}
}

