import { Component } from '@angular/core';
import { Params }   from '@angular/router';

import { Sound } from './sound';
import { TilesComponent } from './tiles.component';
import { Tag } from './tag';

@Component({
  selector: 'market-category',
  templateUrl: './tiles.component.html',
  styleUrls: [ './tiles.component.css' ],
})

export class TagComponent extends TilesComponent
{
  tag : string; // tag to display

  // called after constuctor
  public construct()
  {
    const title = "Sounds by Category - White Noise Market";   
    const description = "Download categories of sounds to White Noise for free.";
    const image = "/assets/images/white-noise-market.png";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }

  // called after ngInit
  public init()
  {
    this.route.params.subscribe((params: Params) => { this.setTag(params['tag'] as string) });
  }

  public setTag(tag: string)
  {
    console.log("Tag Component is requesting tag: " + tag);
    this.tag = tag;

    // setup for following
    this.followTag = tag; // categories use tag value for flags
    this.followFlag = 2;  // categories are type 2
    this.isFollowed = this.messagingService.isSubscribedToTopic(this.followTag);
    
    // setup title
    this.marketService.getTag(tag).then( tag => this.setupTag(tag));

    // pull in sounds for this category
    this.reset();
    this.getSounds();
  }

  // implement the query for limit and offset in the inherited class
  public query(limit: number, offset : number): Promise<Sound[]>
  {
    console.log("Tag Component querying sounds by tag: " + this.tag);
    return this.marketService.getSoundsByTag(this.tag, limit, offset);
  }

  private setupTag(tag : Tag): void
  {
    this.title = tag.Label + " Category";
    this.avatar = tag.ImageUrl;

    const title = this.title + " - White Noise Market";
    var description = tag.Label + " category for White Noise Market sounds.";
    if (tag.Description && tag.Description.length > 0)
    {
      // add to the meta description
      this.subtitle = tag.Description;
      description += " " + tag.Description;
    }
    else
    {
      // add sub title
      this.subtitle = "White Noise Market sounds in the " + tag.Label + " category";
    }

    // add additional info for description meta
    description += " Download these sounds to the White Noise app for free.  Official Market for White Noise Sounds by TMSOFT.";

    // update title and meta data
    this.metaService.updateTitle(title);
    this.metaService.updateDescription(description);
  }

}
